export const NAV_ITEMS = [
  {
    title: "About Me",
    url: "/about-me",
  },
  {
    title: "Projects",
    url: "/my-projects",
  },
  {
    title: "Resources",
    url: "/resources",
  },
  {
    title: "Contact",
    url: "/contact",
  },
];
